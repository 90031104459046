/* Rectangle 57 */

$footer-height: 100px;
html {
  height: 100vh;
}

body {
  height: 100vh;
  font-weight: 400;
}

/* Cocina_Leben_02 4K 1 */

main {
  @extend .py-3;
  height: 100%;
  // background-image: url(/assets/images/cocina.png);
  background-size: cover;
  background-repeat: no-repeat;
  .container {
    padding-top: 180px;
    @media only screen and (min-width: 768px) {
      padding-top: 165px;
    }
  }

  img {
    width: 80px;
    height: 80px;
  }

  .title {
    margin-top: 4.8%;
  }

  .row {
    @extend .mb-3;

    .btn-block {
      @extend .mb-3;
    }
  }
}

.image-container {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;

  .coming-soon {
    position: absolute;
    right: 0;
    left: 0;
    top: 10%;
    background-color: black;
    color: white;
  }

  .image-detail {
    @extend .absolute-bottom;

    display: flex;
    flex-direction: column;
    background-color: #000000a8;
    color: white;
    text-transform: uppercase;
    padding: 2rem 1rem 0.5rem;
    height: 25%;
    .solution-title {
      font-size: 1.1rem;
    }
    .solution-start {
      font-size: 0.7rem;
      text-decoration: underline;
      margin-top: auto;
    }
  }
  .image-description {
    @extend .image-detail;

    transition: background-color 0.5s ease;
    background-color: #00000000;
    height: 100%;
    color: #00000000;
    padding: 31px 24px 0px 24px;
    .solution-start {
      text-align: center;
      font-size: 0.7rem;
      text-decoration: underline;
      margin-top: auto;
    }
  }
  &:hover {
    .image-description {
      text-align: start;
      font-size: 0.8rem;
      background-color: black;
      color: white;
      padding-bottom: 0;

      p {
        color: white;
        text-align: left;
        font-size: 14px;
        letter-spacing: 0px;
        text-transform: none;
      }
    }
  }
}

a.btn.btn-block {
  text-transform: uppercase;
}
.w-15 {
  width: 15%;
}

.hbt-logo {
  width: 15%;
  @media only screen and (max-width: 600px) {
    width: 100%;
  }

  /* Small devices (portrait tablets and large phones, 600px and up) */
  @media only screen and (min-width: 600px) {
    width: 30%;
  }

  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    width: 25%;
  }

  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    width: 20%;
  }

  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1200px) {
    width: 10%;
  }
}

.black-navbar {
  display: flex;
  width: 100vw;
  margin-left: -31px;
  min-height: 2rem;
  background-color: black;
  color: white;
  text-align: center;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

.history {
  position: absolute;
  right: 1px;
}

.split {
  width: 0px;
  height: 33px;
  border: 1px solid #f1f1f1;
}

.shopping-cart {
  display: flex;
  flex-direction: row;
  gap: 21px;
}
