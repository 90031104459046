$orange: #d7aa1e;
$purple: #595091;
$white:  #ffffff;
$black: #000000;
$primary: $black;


$body-bg: $white;
$body-color: $black;


$font-family-sans-serif: 'Muli', "Segoe UI", Roboto, "Helvetica Neue", sans-serif;



$font-weight-light:           300 !default;
$font-weight-normal:          400 !default;
$font-weight-bold:            700 !default;
$font-weight-base:            $font-weight-bold;

$headings-font-weight:        $font-weight-bold;